a {
  text-decoration: none;
}

// *::-webkit-scrollbar {
//   width: 0;
// }

*::-webkit-scrollbar-track {
  background: transparent;
}



